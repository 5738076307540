import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { prefix } from "../components/offer-section.utils";
import offerDetailsCss from "../components/offer-details.module.css";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageTitle from "../components/page-title";
import Showcase from "../components/showcase";

// style
const pStyles = {
  lineHeight: "1.4rem",
  margin: "1rem 0",
};

const background = "#20222d";

// data
const meta = [
  {
    img: "zaluzja-2",
    text: {
      title: "Żaluzja aluminiowa",
      description:
        "Żaluzja aluminiowa drewnopodobna",
    },
  },
  {
    img: "zaluzja-1",
    text: {
      title: "Żaluzja aluminiowa w kolorze",
      description:
        "Żaluzja aluminiowa to ekonomiczne i estetyczne rozwiązanie dla każdego okna - szeroki wybór kolorów",
    },
  },
  {
    img: "zaluzja-drewniana-1",
    text: {
      title: "Żaluzja drewniana",
      description:
        "EKO design - naturalne drewno",
    },
  },
  {
    img: "zaluzja-drewniana-2",
    text: {
      title: "Żaluzja drewniana",
      description:
        "Żaluzje drewniane to ekologiczna propozycja dla domu",
    },
  },
  {
    img: "zaluzja-drewniana-3",
    text: {
      title: "Żaluzja drewniana",
      description:
        "EKO design",
    },
  },
  {
    img: "zaluzja-drewniana-4",
    text: {
      title: "Żaluzja drewniana",
      description:
        "EKO design",
    },
  },
  {
    img: "zaluzja-drewniana-5",
    text: {
      title: "Żaluzja aluminiowa",
      description:
        "Drewnopodobna",
    },
  },
];

// markup
const ZaluzjePage = () => {
  // data
  const descriptions = [
    "Żaluzje stanowią klasyczny i sprawdzony system osłon okiennych. Dzięki swojemu ponad czasowemu wyglądowi idealnie uzupełnią każde wnętrze, dodając niepowtarzalnego klimatu. Żaluzje wykazują dobre właściwości termoizolacyjne.",
    "Za sprawą regulacji pochylenia lamet, możemy ochronić wnętrze domu przed przegrzaniem oraz nadmiernym nasłonecznieniem. Zimą żaluzje pomagają w właściwej cyrkulacji powietrza, a co za tym idzie, podnosi się średnia temperatura. Dodatkowo żaluzje mają zastosowanie prywatyzujące, za ich sprawą ukryjemy wnętrze domu, nie zabierając przy tym dużej ilości światła słonecznego. Oferujemy żaluzje drewniane oraz aluminiowe, w wielu kolorach. Na pewno znajdą Państwo odpowiedni model do swojego wnętrza.       ",
  ];

  const { images } = useStaticQuery(graphql`
    query ZaluzjeImages {
      images: allFile(filter: { relativeDirectory: { eq: "showcase/zaluzje" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  const final = images.edges.map((edge) => {
    const texts = meta.find((m) => m.img === edge?.node?.name);
    edge.node.meta = { ...texts.text };
    return edge;
  });
  return (
    <Layout>
      <SEO
        title="Rolety Lider | Żaluzje"
        description="Żaluzje w szerokiej gamie kolorów i materiałów. Żaluzje metalowe i drewniane. Tylko sprawdzeni producenci."
      />
      <section style={{ marginTop: "-6.1rem" }}>
        {prefix(true, background)}
        <article
          style={{
            background,
          }}
          className={offerDetailsCss.article}
        >
          <PageTitle showBack="true" title="Żaluzje" />
          <div className={offerDetailsCss.text}>
            {descriptions.map((text, i) => (
              <p key={i} style={pStyles}>
                {text}
              </p>
            ))}
          </div>
          <Showcase images={final} />
        </article>
      </section>
    </Layout>
  );
};

export default ZaluzjePage;
